import React from 'react'
import { Link } from 'gatsby'

function LinkBankItem(props) {
  const { title, pageSlug, categorySlug } = props
  return (
    <li className="link-bank-item">
      <Link to={`/${pageSlug}#${categorySlug}`}>{title}</Link>
    </li>
  )
}

export default LinkBankItem
