import React from 'react'
import Image from 'gatsby-image'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { FaArrowRight } from 'react-icons/fa'

export default function SubCategory(props) {
  const { slug, title, content, shopAce, image } = props
  return (
    <div id={slug} className="category-group">
      <h2 className="headline underline">{title}</h2>
      <div className="category-item">
        <div className="category-img">
          <Image fixed={image.localFile.childImageSharp.fixed} />
        </div>
        <div
          className="category-content font-body"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      {shopAce && (
        <OutboundLink
          className="button-cta"
          href={`https://www.acehardware.com/departments/${slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`Shop ${title}`}
          <FaArrowRight className="icon-space--sm-left" />
        </OutboundLink>
      )}
    </div>
  )
}
