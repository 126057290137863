import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../../components/Layout'
import Breadcrumbs from '../../components/Breadcrumbs'
import SubCategory from '../../components/SubCategory'
import LinkBankItem from '../../components/LinkBankItem'

export default class DepartmentsPage extends Component {
  render() {
    const { data } = this.props
    const currentPage = data.wordpressPage
    const categories = data.allWordpressPage.edges
    const { title, slug, content, acf } = currentPage

    return (
      <Layout location={slug} pageTitle={title}>
        <div className="container">
          <Breadcrumbs title={title} />
          <h2 className="title-archive">{`Store ${title}`}</h2>
          <div className=" container--departments">
            <div className="container--link-bank">
              <ul className="link-bank">
                {categories.map(category => (
                  <LinkBankItem
                    key={category.node.wordpress_id}
                    pageSlug={slug}
                    categorySlug={category.node.slug}
                    title={category.node.title}
                  />
                ))}
              </ul>
            </div>
            <div className="container--category">
              <div className="hero-outer">
                <div className="overlay">
                  <Image
                    className="hero-inner"
                    fluid={acf.hero_image.localFile.childImageSharp.fluid}
                  />
                </div>
                <div className="title-page-container fade-in">
                  <h2
                    className="title-page-cat"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </div>
              </div>
              <div
                className="font-body"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <div className="category-col">
                {categories.map(category => (
                  <SubCategory
                    key={category.node.wordpress_id}
                    slug={category.node.slug}
                    title={category.node.title}
                    content={category.node.content}
                    image={category.node.acf.hero_image}
                    shopAce
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "departments" }) {
      slug
      title
      content
      excerpt
      acf {
        hero_image {
          id
          source_url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allWordpressPage(filter: { wordpress_parent: { eq: 421 } }) {
      edges {
        node {
          wordpress_id
          slug
          title
          content
          excerpt
          acf {
            hero_image {
              id
              source_url
              localFile {
                childImageSharp {
                  fixed(width: 280) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
